<template>
  <v-dialog
    ref="dialog"
    width="290px"
    v-model="dialog"
    :return-value.sync="pickerDate"
    :persistent="persistent"
  >
    <template v-slot:activator="{ on }">

      <maskfield
        ref="field"
        class="datefield"
        v-bind="$attrs"
        v-on="listeners"
        :value="display"
        :mask="mask"
        :error="!isValid"
        :color="color"
        :append-icon="isFocused ? appendIcon : undefined"
        return-masked
      >

        <template v-for="name in slots" v-slot:[name]>
          <slot :name="name"/>
        </template>

      </maskfield>
    </template>

    <v-date-picker
      timezone="Europe/Madrid"
      v-model="date"
      :color="color"
      :min="pickerMin"
      :max="pickerMax"
    >
      <template v-if="persistent">
        <slot name="date-picker-content">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="onCancel">
            {{ cancelText }}
          </v-btn>
          <v-btn color="primary" elevation="0" tile @click="dialog = false">
            {{ acceptText }}
          </v-btn>
        </slot>
      </template>
    </v-date-picker>

  </v-dialog>
</template>

<script>
import Maskfield from './Maskfield';
import moment from 'moment';
import { slots, createEvents, getMoment } from './utils';

export default {
  inheritAttrs: false,
  components: { Maskfield },
  props: {
    value: null,
    returnDate: Boolean,
    color: {
      type: String,
      default: 'primary'
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    appendIcon: {
      type: String,
      default: 'mdi-calendar-month'
    },
    min: [ String, Number, Date ],
    max: [ String, Number, Date ],
    persistent: Boolean,
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    acceptText: {
      type: String,
      default: 'Ok'
    }
  },
  data() {
    return {
      slots,
      display: undefined,
      moment: null,
      date: null,
      pickerDate: null,
      isFocused: false,
      dialog: false
    }
  },
  computed: {
    listeners() {
      return createEvents( this, {
        input: this.onInput,
        focused: this.onFocused,
        'click:append': this.onClickAppend
      });
    },
    mask() {
      return this.format.replace(/[dmy]/gi,'d') || 'dd/dd/dddd';
    },
    computedMin() {
      return getMoment( this.min, this.format );
    },
    computedMax() {
      return getMoment( this.max, this.format );
    },
    pickerMin() {
      return this.computedMin
        ? this.computedMin.format('YYYY-MM-DD')
        : null;
    },
    pickerMax() {
      return this.computedMax
        ? this.computedMax.format('YYYY-MM-DD')
        : null;
    },
    isBeforeMin() {
      return this.moment && this.computedMin && this.moment.isBefore( this.computedMin );
    },
    isAfterMax() {
      return this.moment && this.computedMax && this.moment.isAfter( this.computedMax );
    },
    isValid() {
      if ( ! this.moment ) return true;
      if ( this.isBeforeMin || this.isAfterMax ) return false;
      return this.moment.isValid();
    }
  },
  watch: {
    value: 'set',
    dialog( value ) {
      if ( ! value ) {
        this.$refs.dialog && this.$refs.dialog.save( this.date );
        setTimeout( this.focus );
      }
    },
    moment( value ) {
      if ( ! value || ! value.isValid()) this.date = undefined;
      else this.date = value.format('YYYY-MM-DD');
    },
    pickerDate( value ) {
      value && ( value = moment( value, 'YYYY-MM-DD' ).format( this.format ));
      this.onInput( value );
    }
  },
  methods: {
    set( value ) {
      this.setValue( value );
      this.setDisplay();
    },
    setValue( value ) {
      if ( value == null || value == '' ) this.moment = null;
      else this.moment = getMoment( value, this.format ) || moment.invalid();
    },
    setDisplay() {
      const m = this.moment;
      this.display = m && m.isValid() ? m.format( this.format ) : undefined;
    },
    onCancel() {
      this.date = ( this.moment && this.moment.format('YYYY-MM-DD')) || null;
      this.dialog = false;
    },
    onInput( value ) {

      this.display = value;
      this.setValue( value );
      const m = this.moment;

      if ( m ) {
        m.isValid() && this.$emit( 'input', this.returnDate ? m.toDate() : m.valueOf());
      } else {
        this.$emit( 'input', undefined );
      }
    },
    onFocused( value ) {
      this.isFocused = value;
    },
    onClickAppend( event ) {
      this.dialog = true;
      this.$emit( 'click:append', event );
    },
    /** @public */
    focus() {
      this.$refs.field && this.$refs.field.focus();
    },
    /** @public */
    blur( event ) {
      this.$refs.field && this.$refs.field.blur();
    }
  },
  beforeMount() {
    this.set( this.value );
  }
};
</script>
