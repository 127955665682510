<template>
  <textfield
    ref="field"
    type="text"
    class="maskfield"
    v-bind="$attrs"
    v-on="listeners"
    :value="display"
    :prevent-key="preventKey"
  >

    <template v-for="name in slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>

  </textfield>
</template>

<script>
import Textfield from './Textfield';
import { slots, createEvents } from './utils';
import { keyCodes } from 'vuetify/lib/util/helpers';

const KEY_CODES = Object.values( keyCodes );
const ALPHA_REG = /[a-z]/i;
const NUMBER_REG = /\d/;

const defaultOptions = {
  number: 'd',
  alpha: 'a',
  special: 's'
};

export default {
  inheritAttrs: false,
  components: { Textfield },
  props: {
    value: null,
    mask: String,
    options: Object,
    returnMasked: Boolean
  },
  data() {
    return {
      slots,
      display: undefined,
      internal: undefined
    }
  },
  computed: {
    listeners() {
      return createEvents( this, {
        input: this.onInput
      });
    },
    computedOptions() {
      return {
        ...defaultOptions,
        ...this.options
      }
    },
    isComplete() {
      return this.mask && ( this.display || '' ).length >= this.mask.length;
    }
  },
  watch: {
    value: 'setValue',
    mask: 'setValue',
    options: 'setValue'
  },
  methods: {
    setValue( value ) {
      if ( ! value ) {

        this.internal = undefined;
        this.display = undefined;

      } else if ( ! this.mask ) {

        this.internal = value;
        this.display = value;

      } else {

        var r = this.computeValue( value );
        this.internal = r.value || undefined;
        this.display = r.display || undefined;
      }
    },
    computeValue( value ) {

      value = String( value );
      const { mask } = this;

      var result = { value: '', display: '' };
      var length = Math.max( mask.length, value.length );

      for ( var m = 0, c = 0, a, b, mode; m < length; m++ ) {

        if ( m >= mask.length ) break;
        if ( c >= value.length ) break;

        a = value.charAt(c);
        b = mask.charAt(m);
        mode = this.getMode(b);

        if ( mode ) {
          if ( this.validateChar( a, mode )) {
            result.display += a;
            result.value += a;
            c++;
          } else {
            return result;
          }
        } else if ( a === b ) {
          result.display += a;
          result.value += a;
          c++;
        } else {
          result.display += b;
        }
      }

      return result;
    },
    getMode( char ) {
      const opts = this.computedOptions;
      for ( var key in opts ) {
        if ( opts[key] === char ) return key;
      }
    },
    getNextMode() {
      const value = this.display;
      if ( ! this.mask ) return;
      if ( ! value ) return this.getMode( this.mask.charAt( 0 ));
      else {
        for ( var m = 0, c = 0, a, b, mode; m < this.mask.length; m++ ) {

          a = value.charAt(c);
          b = this.mask.charAt(m);
          mode = this.getMode(b);

          if ( mode ) {
            if ( c >= value.length ) return mode;
            if ( this.validateChar( a, mode )) c++;
            else return;
          } else if ( a === b ) c++;
        }
      }
    },
    validateChar( char, mode ) {
      switch ( mode ) {
        case 'number':
          return NUMBER_REG.test( char );
        case 'special':
          return !ALPHA_REG.test( char );
        default:
          return ALPHA_REG.test( char );
      }
    },
    preventKey( event ) {

      if ( event.ctrlKey ) return false;
      if ( KEY_CODES.includes( event.keyCode )) return false;
      if ( this.isComplete ) return true;

      const mode = this.getNextMode();
      return !this.validateChar( event.key, mode );
    },
    onInput( value ) {
      this.setValue( value );
      this.$emit( 'input', this.returnMasked ? this.display : this.internal );
    },
    /** @public */
    focus() {
      this.$refs.field && this.$refs.field.focus();
    },
    /** @public */
    blur( event ) {
      this.$refs.field && this.$refs.field.blur();
    }
  },
  beforeMount() {
    this.setValue( this.value );
  }
};
</script>
