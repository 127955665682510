<template>
  <selectfield
    class="disposition-selector"
    v-bind="$attrs"
    v-on="listeners"
    :items="items"
    :attach="false"
    :menu-props="menuProps"
    :loading="loading"
    @blur="search = ''"
    item-value="id"
    item-text="disposition"
  >

    <template v-slot:selection="{ selected }">
      <span v-html="getTitle( selected.item )"/>
    </template>

    <template v-slot:item="{ item }">
      <span v-html="getTitle( item )"/>
    </template>

    <template v-slot:append-outer="{ active }">

      <div
        v-show="active"
        class="dispositions-selector-search"
      >
        <textfield
          v-model="search"
          class="mb-0"
          append-icon="mdi-magnify"
          placeholder="Cerca per número de disposició..."
          background-color="white"
          hide-details
          filled
        />
      </div>

    </template>

  </selectfield>
</template>

<script>
import { mapState } from 'vuex';
import { dispositionTitle } from '@/utils';
import Textfield from '@/components/inputs/Textfield';
import Selectfield from '@/components/inputs/Selectfield';

export default {
  components: { Selectfield, Textfield },
  data: () => ({
    search: '',
    items: [],
    loading: false,
    lazyValue: null,
    menuProps: {
      nudgeBottom: 40
    }
  }),
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput
      }
    }
  },
  watch: {
    search: 'getItems'
  },
  methods: {
    onInput( value ) {
      this.lazyValue = this.items.find( a => a.id === value );
      this.$listeners.input && this.$listeners.input( value );
    },
    getTitle( disposition ) {
      return dispositionTitle( disposition );
    },
    getItems( num_disposition ) {

      clearInterval( this.interval );
      if ( ! num_disposition  ) {
        this.items = [ this.lazyValue ].filter( Boolean );
        return;
      }

      this.interval = setTimeout(() => {

        this.loading = true;
        this.$store
          .dispatch( 'getDispositions', { filters: { num_disposition } })
          .then( items => {
            this.items = items;
            if ( this.lazyValue && ! items.find( a => a.id === this.lazyValue.id )) {
              items.unshift( this.lazyValue );
            }
          })
          .finally(() => this.loading = false );

      }, 500 );
    }
  },
  mounted() {
    this.getItems( this.search );
  }
}
</script>

<style>
.dispositions-selector-search {
  padding: 4px;
  background-color: #EEE;
  border-left: 1px solid var(--v-primary-base);
  border-right: 1px solid var(--v-primary-base);
}
/*.selectfield__menu_content .selectfield__item > div {
  max-width: 540px;
}
.dispositions-selector-search .input__wrapper {
  border-radius: 0 !important;
  border-color: var(--v-primary-base) !important;
  border-top: 0 !important;
}*/
</style>
