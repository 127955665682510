<template>
  <input-component
    ref="field"
    :class="classNames"
    v-bind="$attrs"
    v-on="listeners"
    :value="internalValue"
    :single-line="!!placeholder || singleLine"
  >
    <template v-slot="props">
      <textarea
        ref="input"
        :value="internalValue"
        v-bind="inputProps"
        @input="onInput( $event.target.value )"
        @focus="focus"
        @blur="blur"
        @keydown="onKeyDown"
        @keyup="$emit( 'keyup', $event )"
        @keypress="$emit( 'keypress', $event )"
      />
    </template>

    <template v-for="name in slots" v-slot:[name]>
      <slot :name="name"/>
    </template>

  </input-component>
</template>

<script>
import InputComponent from './Input';
import { slots, createEvents, clickEvent } from './utils';
import { convertToUnit, getObjectValueByPath } from 'vuetify/lib/util/helpers';

export default {
  inheritAttrs: false,
  components: { InputComponent },
  props: {
    value: null,
    placeholder: String,
    autofocus: Boolean,
    preventKey: Function,
    autoGrow: Boolean,
    noResize: Boolean,
    singleLine: Boolean,
    maxHeight: [ Number, String ],
    minHeight: [ Number, String ],
    rowHeight: {
      type: [ Number, String ],
      default: 24,
      validator: v => !isNaN(parseFloat(v))
    },
    rows: {
      type: [ Number, String ],
      default: 5,
      validator: v => !isNaN(parseInt(v, 10))
    }
  },
  data() {
    return {
      slots,
      internalValue: this.value || undefined
    }
  },
  computed: {
    classNames() {
      return {
        'textareafield': true,
        '--auto-grow': this.autoGrow,
        '--no-resize': this.noResizeHandle,
      }
    },
    noResizeHandle() {
      return this.noResize || this.autoGrow;
    },
    inputProps() {
      return {
        'aria-readonly': this.$refs.field && String( this.$refs.field.isReadonly ),
        placeholder: this.placeholder,
        autocomplete: getObjectValueByPath( this.$attrs, 'autocomplete', 'off' ),
        readonly: this.$refs.field && this.$refs.field.isReadonly,
        disabled: this.$refs.field && this.$refs.field.isDisabled,
        autofocus: this.autofocus,
        rows: this.rows,
        style: {
          maxHeight: convertToUnit( this.maxHeight ),
          minHeight: convertToUnit( this.minHeight )
        }
      }
    },
    listeners() {
      return createEvents( this, {
        'click:label': clickEvent( this, 'click:label' ),
        'click:clear': clickEvent( this, 'click:clear' ),
        'click:append': clickEvent( this, 'click:append' ),
        'click:prepend': clickEvent( this, 'click:prepend' ),
        input: null,
        focus: null,
        blur: null
      });
    }
  },
  watch: {
    value: 'setValue',
    internalValue() {
      this.autoGrow && this.$nextTick( this.calculateInputHeight );
    },
    rowHeight() {
      this.autoGrow && this.$nextTick( this.calculateInputHeight );
    }
  },
  methods: {
    setValue( value ) {
      this.internalValue = value || undefined;
    },
    onInput( value ) {
      this.setValue( value );
      this.$emit( 'input', value );
    },
    calculateInputHeight() {
      const { input } = this.$refs;
      if ( ! input ) return;
      input.style.height = '0';
      const height = input.scrollHeight;
      const minHeight = parseInt( this.rows, 10 ) * parseFloat( this.rowHeight );
      input.style.height = Math.max( minHeight, height ) + 'px';
    },
    tryAutofocus() {
      if (
        !this.autofocus ||
        typeof document === 'undefined' ||
        !this.$refs.input ||
        document.activeElement === this.$refs.input
      ) return false;
      this.focus();
      return true;
    },
    onKeyDown( event ) {
      this.$emit( 'keydown', event );
      if ( this.preventKey && this.preventKey( event )) {
        event.preventDefault();
        return false;
      }
    },
    /** @public */
    focus( event ) {
      this.$refs.input && this.$refs.input.focus();
      this.$refs.field && ( this.$refs.field.isFocused = true );
      event && this.$emit( 'focus', event );
    },
    /** @public */
    blur( event ) {
      if ( this.$refs.field && this.$refs.field.hasMouseDown ) return;
      this.$refs.field && ( this.$refs.field.isFocused = false );
      this.$refs.input && this.$refs.input.blur();
      event && this.$emit( 'blur', event );
    }
  },
  mounted() {
    this.tryAutofocus();
    setTimeout(() => {
      this.autoGrow && this.calculateInputHeight();
    }, 0 );
  }
};
</script>

<style>
.textareafield textarea {
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.35rem;
  width: 100%;
  max-width: 100%;
  min-height: 34px;
  outline: 0;
  padding: .5em;
}
.textareafield.--auto-grow textarea {
  overflow: hidden;
}
.textareafield.--no-resize textarea {
  resize: none;
}
.textareafield .input__wrapper {
  padding: 0;
}
.textareafield .input__prepend,
.textareafield .input__append {
  height: 34px !important;
  padding: 0 .5em;
}
.textareafield.--has-prepend .input__label {
  margin-left: 0;
}
.textareafield.--has-prepend textarea {
  padding-left: 0;
}
.textareafield.--has-append textarea {
  padding-right: 0;
}
.textareafield .input__prefix,
.textareafield .input__sufix {
  height: 34px !important;
}
.textareafield .input__label {
  bottom: auto;
  top: -1.5em;
}
.textareafield.--single-line .input__label {
  height: 34px;
  bottom: auto;
  top: 0;
}
</style>
