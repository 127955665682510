<template>
  <selectfield
    v-bind="$attrs"
    v-on="$listeners"
    :items="types"
    :attach="false"
    item-value="id"
  />
</template>

<script>
import { mapState } from 'vuex';
import Selectfield from '@/components/inputs/Selectfield';

export default {
  components: { Selectfield },
  computed: mapState( 'filters', [ 'types' ]),
  created() {
    this.$store.dispatch('loadHome');
  }
}
</script>
