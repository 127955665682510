import { normalize } from './index';

export const sortCategories = ( a, b ) => {
  if ( a.type !== b.type ) return a.type - b.type;
  return a.position - b.position;
}

export const groupCategories = ( categories, sort ) => {

  var arr = [], parents = {};
  sort && ( categories = categories.slice().sort( sortCategories ));

  categories.forEach( cat => {

    cat = { ...cat, children: [] };
    parents[ cat.id ] = cat;

    if ( ! parents[ cat.parent ]) {
      arr.push( cat );
    } else {
      cat._parent = parents[ cat.parent ];
      cat._parent.children.push( cat );
    }
  });

  return arr;
}

const selectCategory = ( cat, arr, categories ) => {
  arr = arr || [];
  var parent;
  if ( arr.indexOf( cat ) < 0 ) arr.push( cat );
  if ( cat.parent && ( parent = categories.find( c => c.id === cat.parent ))) {
    arr = selectCategory( parent, arr, categories );
  }
  return arr;
}

export const filterCategories = ( search, categories ) => {

  search = normalize( search || '' ).trim();
  if ( ! search ) return null;

  var result = [];
  categories.forEach(( cat, parent ) => {
    if ( normalize( cat.text ).indexOf( search ) > -1 ) {
      selectCategory( cat, result, categories );
    }
  });

  return result;
};
