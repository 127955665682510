<template>
  <div class="categories layer">
    <v-sheet
      class="categories--header"
      color="white"
    >

      <p class="subtitle text-uppercase mb-3">
        Categories
        <btn
          v-if="realSelected.length"
          class="float-right"
          color="primary"
          @click="setSelected([])"
          x-small
        >
          <span class="text-none">
            Deseleccionar ({{ realSelected.length }})
          </span>
        </btn>
      </p>

      <textfield
        :value="search"
        placeholder="Cerca les categories"
        background-color="#f2f2f2"
        append-icon="mdi-magnify"
        @input="changeSearch"
        clearable
        outlined
        filled
      />

    </v-sheet>
    <div class="categories--content">
      <expansion-menu
        class="layer"
        :value="realSelected"
        :items="filterdCategories || categories"
        @input="onInput"
        hide-counter
        opened
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { filterCategories } from '@/utils/categories';
import ExpansionMenu from './ExpansionMenu';
import Textfield from '@/components/inputs/Textfield';
import Btn from '@/components/Btn';

export default {
  components: { ExpansionMenu, Textfield, Btn },
  props: {
    value: Array
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState( 'categories', [ 'categories', 'selected' ]),
    realSelected() {
      return this.value || this.selected;
    },
    filterdCategories() {
      return filterCategories( this.search, this.categories );
    }
  },
  methods: {
    setSelected( value ) {
      if ( ! this.value ) {
        this.$store.commit( 'categories/setSelected', value );
      } else {
        this.$emit( 'input', value );
      }
    },
    onInput( value ) {
      clearInterval( this.interval );
      this.interval = setTimeout(() => this.setSelected( value ), 500 );
    },
    changeSearch( search ) {
      clearInterval( this.interval2 );
      this.interval2 = setTimeout(() => this.search = search, 250 );
    }
  }
}
</script>

<style>
.categories--header {
  position: absolute;
  padding: 24px 16px 16px;
  top: 0;
  width: 100%;
  height: 108px;
}
.categories--content {
  position: absolute;
  width: 100%;
  top: 108px;
  bottom: 0;
  overflow: auto;
}
</style>
