<script>
// Components
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VItem, VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

// Directives
import Ripple from 'vuetify/lib/directives/ripple';

// Mixins
import colorable from 'vuetify/lib/mixins/colorable';
import themeable from 'vuetify/lib/mixins/themeable';

// Utils
import { keyCodes } from 'vuetify/lib/util/helpers';
import { sortCategories, groupCategories } from '@/utils/categories';

var rendered = 0;

export default {
  name: 'expansion-menu',
  inheritAttrs: false,
  directives: { Ripple },
  mixins: [ colorable, themeable ],
  components: {
    VExpandTransition,
    VSimpleCheckbox,
    VItemGroup,
    VItem,
    VCard,
    VIcon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    ripple: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    opened: Boolean,
    itemClass: String,
    hideCounter: Boolean,
    closePanelIcon: {
      type: String,
      default: 'mdi-plus'
    },
    openPanelIcon: {
      type: String,
      default: 'mdi-minus'
    },
    rowHeight: {
      type: [ Number, String ],
      default: 31
    }
  },
  data: () => ({
    selected: [],
    panels: {},
    count: {}
  }),
  computed: {
    sortedItems() {
      return this.items.slice().sort( sortCategories );
    },
    internalItems() {
      return groupCategories( this.sortedItems );
    },
    textColor() {
      return this.setTextColor( this.color );
    },
    compRowHeight() {
      return parseFloat( this.rowHeight );
    }
  },
  watch: {
    value: 'computeValue',
    items( value ) {
      this.createPanelControl( value );
      this.count = this.sumAllCounts();
      this.$nextTick(() => this.selected = this.selected.slice());
    }
  },
  methods: {
    computeValue( value ) {
      this.selected = [];
      ( value || [] ).forEach(( id, item ) => {
        if (( item = this.find( id ))) {
          this.select( item, true, true );
        }
      });
    },
    createPanelControl( items ) {
      const panels = {};
      const _default = !!this.opened;
      ( items || this.items ).forEach( item => panels[item.id] = this.panels[item.id] || _default );
      this.panels = panels;
    },
    sumAllCounts( arr ) {
      var count = {};
      ( arr || this.internalItems ).forEach( item => {
        count[item.id] = this.sumCount( item );
        if ( item.children.length ) Object.assign( count, this.sumAllCounts( item.children ));
      });
      return count;
    },
    sumCount( item ) {
      if ( item.children.length ) return item.children.reduce(( v, a ) => v += this.sumCount( a ), 0 );
      return item.count;
    },
    emitSelections() {

      this.items.forEach( item => item.hasSelected = false );

      this.selected.forEach(( id, item ) => {
        item = this.items.find( a => a.id === id );
        while ( item && this.panels[item.parent] ) {
          this.panels[item.parent].hasSelected = true;
          item = this.panels[item.parent];
        }
      });
    },
    genItemGroup( items, data ) {
      return this.$createElement( VItemGroup, data, this.genContent( items ));
    },
    genContent( items ) {
      return items.map( item => {
        return item.children.length
          ? this.genExpansionPanel( item )
          : this.genItem( item );

      });
    },
    genItem( item, isPanel ) {

      const isOpened = isPanel && this.panels[item.id];
      const hasSelected = isPanel && this.hasSelected( item );

      return this.$createElement( VItem, {
        key: item.id,
        props: {
          value: item.id
        },
        scopedSlots: {
          default: props => this.$createElement( VCard, {
            staticClass: [ '--item', this.itemClass || '' ].join(' '),
            style: props.active && this.textColor.style,
            attrs: { 'data-id': item.id },
            class: {
              '--active': this.selected.includes( item.id ),
              '--has-active': hasSelected,
              '--opened': isOpened,
              ...( props.active && this.textColor.class )
            },
            props: {
              ripple: this.ripple,
              dark: this.dark,
              light: this.light,
              disabled: item.disabled,
              tile: true,
              flat: true
            },
            on: {
              click: e => this.onClickItem( e, item ),
              keydown: e => this.onPressItem( e, item )
            }
          },[
            !isPanel && this.$createElement( VSimpleCheckbox, {
              staticClass: '--checkbox',
              props: {
                value: this.selected.includes( item.id ),
                color: this.color,
                dark: this.dark,
                light: this.light,
                ripple: false
              },
              on: {
                click: e => this.onClickItem( e, item ),
                keydown: e => this.onPressItem( e, item )
              }
            }),
            isPanel && this.$createElement( VIcon, {
              staticClass: '--icon',
              props: {
                dark: this.dark,
                light: this.light
              },
              on: {
                click: e => this.onClickIcon( item, e ),
                keydown: e => e.stopPropagation()
              }
            }, [ isOpened ? this.openPanelIcon : this.closePanelIcon ]),
            this.$createElement( 'span', { staticClass: '--text' }, [ item.text ]),
            this.$createElement( 'div', { staticClass: 'spacer' }),
            !this.hideCounter && this.$createElement( 'span', { staticClass: '--count' }, [`(${this.count[item.id]})`])
          ])
        }
      });
    },
    genExpansionPanel( item ) {
      return this.$createElement( 'div', { staticClass: '--expansion-panel' }, [
        this.genItem( item, true ),
        this.$createElement( VExpandTransition, {},
          ! this.panels[item.id]
            ? null
            : [ this.$createElement( 'div', { staticClass: '--content' }, this.genContent( item.children ))]
        )
      ]);
    },
    find( id, arr ) {
      arr = ( arr || this.internalItems );
      for ( var i = 0, item; i < arr.length; i++ ) {
        if ( arr[i].id === id ) return arr[i];
        if (( item = this.find( id, arr[i].children ))) return item;
      }
    },
    hasSelected( item ) {
      if ( ! item.children.length ) {
        return this.selected.indexOf( item.id ) !== -1;
      } else {
        for ( var i = 0; i < item.children.length; i++ ) {
          if ( this.hasSelected( item.children[i] )) return true;
        }
      }
      return false;
    },
    select( item, selectContent, select ) {

      const index = this.selected.indexOf( item.id );
      const isSelected = select == null
        ? index === -1
        : select;

      if ( index >= 0 ) {
        select !== true && this.selected.splice( index, 1 );
      } else if ( select !== false ) {
        this.selected.push( item.id );
      }

      // Select or unselect parent
      if ( select == null && item._parent ) {
        this.select(
          item._parent,
          false,
          item._parent.children.reduce(( v, i ) => v && this.selected.includes( i.id ), true )
        );
      }

      // Select all content
      if ( selectContent !== false && item.children.length ) {
        item.children.forEach( i => this.select( i, true, isSelected ));
      }

      // Open panel on Select
      if ( this.panels[item.id] != null && isSelected ) {
        this.panels[item.id] = true;
      }

      if ( select == null )
        this.$emit( 'input', this.selected );
    },
    onClickItem( e, item ) {
      this.select( item );
      e && this.$emit( 'click:item', e );
    },
    onClickIcon( item, e ) {
      e.preventDefault();
      e.stopPropagation();
      this.panels[item.id] = !this.panels[item.id];
    },
    onPressItem( e, item ) {
      if ( e.keyCode === keyCodes.enter ) {
        e.preventDefault();
        this.select( item );
      }
    },
    onChange( value ) {
      this.$emit( 'input', value );
    }
  },
  beforeMount() {
    this.computeValue( this.value );
    this.createPanelControl();
    this.count = this.sumAllCounts();
  },
  render() {
    return this.genItemGroup( this.internalItems, {
      staticClass: 'expansion-menu',
      props: {
        value: this.selected,
        multiple: this.multiple
      }
    });
  }
}
</script>

<style>
.expansion-menu {
  font-size: 13px;
  overflow: auto;
}
.expansion-menu .--item {
  display: flex;
  padding: 4px;
}
.expansion-menu .--checkbox {
  align-self: flex-start;
  line-height: 1;
  margin-right: 8px;
  padding: 4px 0;
  color: inherit;
}
.expansion-menu .--checkbox > .v-icon {
  font-size: 1.2em;
  color: inherit;
}
.expansion-menu .--text {
  line-height: 1;
  padding: 5px 0;
}
.expansion-menu .--count {
  font-size: 10px;
  line-height: 1;
  padding: 7px 0;
  margin-right: 4px;
}
.expansion-menu .--icon {
  width: 16px;
  height: 16px;
  margin: 4px;
  font-size: 16px;
}
.expansion-menu .--content {
  padding-left: 20px;
}
.expansion-menu .--content .--content {
  padding-left: 25px;
}
.expansion-menu > .--expansion-panel > .--item > .--text {
  font-weight: bold;
}
.expansion-menu > .--expansion-panel > .--item,
.expansion-menu .--content > .--expansion-panel > .--item {
  color: black;
}
.expansion-menu .--active,
.expansion-menu .--active .--icon,
.expansion-menu .--has-active:not(.--opened),
.expansion-menu .--has-active:not(.--opened) .--icon {
  color: var(--v-primary-base) !important;
}
</style>
