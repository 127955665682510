<template>
  <input-component
    ref="elm"
    class="selectfield"
    v-bind="$attrs"
    :id="computedID"
    :class="classnames"
    :value="internalValue"
    :rules="computedRules"
    :append-icon="appendIcon"
    :single-line="!!placeholder || singleLine"
    :outlined="outlined"
    :tile="tile"
    @mousedown="$emit('mousedown',$event)"
    @mouseup="$emit('mouseup',$event)"
    @click="$emit('click',$event)"
    @click:clear="onClickClear"
    @click:append="onClickAppend"
    v-click-outside="{ handler: blur, closeConditional }"
  >

    <template v-slot="props">
      <div
        ref="selection"
        class="selectfield__selection"
        @click="focus( null, true )"
      >
        <slot
          v-if="props.dirty"
          v-bind="{ ...props, selected }"
          name="selection"
        >
          <template v-if="chips">
            <span v-if="ellpisisLeft" class="selectfield__ellipsis">
              ...
            </span>
            <div
              ref="chips"
              class="selectfield__chips"
            >
              <v-card
                v-for="( item, index ) in ( selected ? (multiple ? selected : [selected]) : [] )"
                v-bind="computedChips"
                :key="item.value"
                tabindex="-1"
                link
              >
                {{ item.text }}
                <v-icon
                  v-if="deletableChips"
                  tabindex="-1"
                  :ripple="false"
                  @click.stop="unselectItem( index )"
                >
                  $clear
                </v-icon>
              </v-card>
            </div>
            <span v-if="ellpisisRight" class="selectfield__ellipsis">
              ...
            </span>
          </template>
          <span v-else>
            {{ multiple ? selected.map( i => i.text ).join(', ') : selected.text }}
          </span>
        </slot>
        <span
          v-else-if="placeholder"
          class="selectfield__placeholder"
        >
          {{ placeholder }}
        </span>
        <input
          ref="input"
          class="selectfield__input"
          v-bind="inputProps"
          @keydown="onKeyDown"
          @keypress="onKeyPress"
          @focus="focus"
          @blur="blur"
        />
      </div>
    </template>

    <template v-slot:append-outer="scope">

      <slot name="append-outer" v-bind="{ ...itemsScope, ...scope }"/>

      <v-menu
        ref="menu"
        v-bind="computedMenuProps"
        @scroll="onScroll"
      >
        <v-item-group
          class="selectfield__menu"
          :value="selected"
          :multiple="multiple"
          @change="onInput"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"
        >

          <slot name="items" v-bind="{ ...itemsScope, ...scope }">

            <slot name="prepend-item"/>

            <v-item
              v-for="( item, index ) in virtualizedItems"
              v-slot="{ active, toggle }"
              :key="item.value"
              :value="item"
            >
              <v-card
                class="selectfield__item"
                :class="{[itemActiveClass]: active }"
                v-show="hideSelected ? !active : true"
                v-bind="itemsScope.attrs"
                v-on="itemsScope.on"
                @click="toggle"
              >
                <div>
                  <slot name="item" v-bind="item">
                    {{ item.text }}
                  </slot>
                </div>
              </v-card>
            </v-item>

            <slot name="append-item" v-bind="scope"/>

          </slot>

        </v-item-group>
      </v-menu>
    </template>

  </input-component>
</template>

<script>
import InputComponent from './Input';
import { slots } from './utils';
import colorable from 'vuetify/lib/mixins/colorable';
import VMenu from 'vuetify/lib/components/VMenu';
import { getPropertyFromItem, getObjectValueByPath, keyCodes } from 'vuetify/lib/util/helpers';

const defaultMenuProps = {
  closeOnClick: false,
  closeOnContentClick: false,
  offsetY: true,
  disableKeys: true,
  openOnClick: false,
  maxHeight: 200,
  ripple: false
};

export default {
  inheritAttrs: false,
  components: { InputComponent },
  mixins: [ colorable ],
  props: {
    id: String,
    multiple: Boolean,
    disableLookup: Boolean,
    mandatory: {
      type: Boolean,
      default: true
    },
    openOnClear: Boolean,
    returnObject: Boolean,
    outlined: Boolean,
    cacheItems: Boolean,
    hideSelected: Boolean,
    chips: [ Boolean, Object ],
    deletableChips: Boolean,
    tile: Boolean,
    placeholder: String,
    value: null,
    menuProps: {
      type: [ String, Array, Object ],
      default: () => defaultMenuProps
    },
    items: {
      type: Array,
      default: () => []
    },
    itemColor: {
      type: String,
      default: 'primary'
    },
    itemDisabled: {
      type: [ String, Array, Function ],
      default: 'disabled'
    },
    itemText: {
      type: [ String, Array, Function ],
      default: 'text'
    },
    itemValue: {
      type: [ String, Array, Function ],
      default: 'value'
    },
    itemHeight: {
      type: [ Number, String ],
      default: 30
    },
    rules: {
      type: Array,
      default: () => []
    },
    itemActiveClass: {
      type: String,
      default: 'v-item--active'
    },
    attach: null,
    singleLine: Boolean,
    appendIcon: {
      type: String,
      default: '$dropdown'
    }
  },
  data() {
    return {
      slots,
      selected: undefined,
      cachedItems: [],
      tiles: [],
      chipsElms: [],
      chipsIndex: -1,
      listIndex: -1,
      lastItem: 20,
      isActive: false,
      menuCanShow: true,
      hasClickableTiles: true,
      keyboardLookupLastTime: 0,
      keyboardLookupPrefix: '',
      ellpisisLeft: false,
      ellpisisRight: false,
      internalValue: undefined,
      computedValue: undefined,
      lazyValue: undefined,
    }
  },
  computed: {
    classnames() {
      return {
        '--is-menu-active': this.isActive,
        '--has-chips': this.chips,
        '--menu-offsetX': this.computedMenuProps.offsetX,
        '--menu-offsetY': this.computedMenuProps.offsetY,
        '--menu-top': this.computedMenuProps.top,
        '--menu-bottom': this.computedMenuProps.bottom,
        '--menu-left': this.computedMenuProps.top,
        '--menu-right': this.computedMenuProps.bottom,
      }
    },
    computedID() {
      return this.id || `selectfield-${this._uid}`;
    },
    computedMenuProps() {

      let props = typeof this.menuProps === 'string'
        ? this.menuProps.split(',')
        : this.menuProps;

      if ( Array.isArray( props )) {
        props = props.reduce(( acc, p ) => {
          acc[p.trim()] = true;
          return acc;
        }, {});
      }

      props = {
        ...defaultMenuProps,
        elevation: !this.outlined,
        outlined: !!this.outlined,
        tile: this.tile,
        ...props
      };

      // contentClass

      var contentClass = ['selectfield__menu_content'], elevation;
      if ( props.elevation === true ) {
        contentClass.push('elevation-1');
      } else if ( ! isNaN( elevation = parseInt( props.elevation ))) {
        contentClass.push(`elevation-${props.elevation}`);
      } else {
        contentClass.push('elevation-0');
      }

      if ( props.outlined ) contentClass.push('--outlined');
      if ( props.offsetX ) contentClass.push('--offset-x');
      if ( props.offsetY ) contentClass.push('--offset-y');
      if ( props.top ) contentClass.push('--top');
      if ( props.bottom ) contentClass.push('--bottom');
      if ( props.contentClass ) contentClass.push( props.contentClass );

      return {
        nudgeBottom: this.outlined ? 0 : 1,
        ...props,
        //eager: this.eager,
        value: this.menuCanShow && this.isActive,
        role: undefined,
        contentClass: contentClass.join(' '),
        activator: `#${this.computedID} .input__wrapper`,
        attach: this.attach == null ? `#${this.computedID}` : this.attach
      };
    },
    computedChips() {
      if ( ! this.chips ) return null;
      return {
        color: 'grey lighten-2',
        tile: this.tile,
        elevation: 0,
        ...( typeof this.chips !== 'boolean' ? this.chips : null ),
      };
    },
    computedRules() {
      return this.rules.map( rule => {
        return typeof rule === 'function'
          ? v => rule( this.computedValue )
          : rule;
      });
    },
    virtualizedItems() {
      return this.cachedItems.slice( 0, this.lastItem );
    },
    activeTile() {
      return this.tile[ this.listIndex ];
    },
    inputProps() {
      return {
        type: 'text',
        'aria-readonly': this.$refs.elm && String(this.$refs.elm.isReadonly),
        'aria-activedescendant': getObjectValueByPath( this.activeTile, 'id' ),
        autocomplete: getObjectValueByPath( this.$attrs, 'autocomplete', 'off' ),
        readonly: true,
      }
    },
    itemsScope() {
      return {
        cached: this.cachedItems,
        items: this.virtualizedItems,
        select: this.onClickItem.bind( this ),
        focus: this.focus.bind( this ),
        active: this.isActive,
        attrs: {
          //class: 'selectfield__item',
          elevation: 0,
          height: this.itemHeight,
          ripple: this.computedMenuProps.ripple,
          tile: true
        },
        on: {
          mousedown: this.onMouseDown.bind( this ),
          mouseup: this.onMouseUp.bind( this )
        }
      }
    }
  },
  methods: {
    getContent() {
      return this.$refs.menu && this.$refs.menu.$refs.content;
    },
    getTiles() {
      const content = this.getContent();
      if ( ! content ) return;
      this.tiles = Array.from( content.querySelectorAll('.selectfield__item'));
    },
    getChips() {
      const { chips } = this.$refs;
      if ( ! chips ) return;
      this.chipsElms = Array.from( chips.children );
    },
    getActivator() {
      return this;
    },
    setValue( selected ) {
      this.selected = selected;
      if ( ! selected || ( this.multiple && ! selected.length )) {
        this.internalValue = this.computedValue = undefined;
      } else {
        this.internalValue = selected;
        if ( this.multiple ) {
          this.computedValue = selected.map( i => this.returnObject ? i.item : i.value );
        } else {
          this.computedValue = this.returnObject
            ? selected.item
            : selected.value;
        }
      }
    },
    onClickAppend( event ) {
      this.focus( null, true );
      this.$emit( 'click:append', event );
    },
    onClickClear( event ) {
      this.clearableCallback();
      this.$emit( 'click:clear', event );
    },
    onClickItem( toggle ) {
      toggle();
      this.focus( null, this.multiple );
    },
    onInput( selected ) {
      if ( selected !== undefined ) {
        this.setValue( selected );
        this.$emit( 'input', this.computedValue );
      }
    },
    onScroll() {
      const content = this.getContent();
      if ( ! content || this.lastItem > this.cachedItems.length ) return;
      else if ( Math.floor( content.scrollHeight - ( content.scrollTop + content.clientHeight )) <= 0 ) {
        this.lastItem += 20;
      }
    },
    onKeyDown(e) {
      this.chips && this.changeChipIndex(e);
      return VMenu.options.methods.onKeyDown.call( this, e );
    },
    onKeyPress(e) {

      if ( this.$refs.elm && !this.$refs.elm.isInteractive ) return;
      if ( this.multiple || this.disableLookup ) return;

      const KEYBOARD_LOOKUP_THRESHOLD = 500;
      const now = performance.now();

      if ( now - this.keyboardLookupLastTime > KEYBOARD_LOOKUP_THRESHOLD ) {
        this.keyboardLookupPrefix = '';
      }

      this.keyboardLookupPrefix += e.key.toLowerCase();
      this.keyboardLookupLastTime = now;

      const index = this.cachedItems.findIndex( item => {
        return item.text.toLowerCase().startsWith( this.keyboardLookupPrefix );
      });

      if ( index !== -1 ) {
        this.lastItem = Math.max( this.lastItem, index + 5 );
        this.$nextTick(() => this.getTiles());
        setTimeout(() => this.setTile( index ));
      }
    },
    changeListIndex(e) {
      return VMenu.options.methods.changeListIndex.call( this, e );
    },
    changeChipIndex(e) {
      if ( e.keyCode === keyCodes.right ) {
        this.nextChip();
      } else if ( e.keyCode === keyCodes.left ) {
        this.prevChip();
      } else if ([ keyCodes.backspace, keyCodes.del ].includes( e.keyCode ) && this.chipsIndex !== -1 ) {
        this.unselectItem( this.chipsIndex );
        this.$nextTick(() => this.prevChip());
      } else {
        this.chipsIndex = -1;
        return;
      }
      e.preventDefault();
    },
    clearableCallback() {
      this.onInput( this.multiple ? [] : null );
      this.$nextTick( this.focus );
      if ( this.openOnClear ) this.isActive = true;
    },
    closeConditional(e) {
      if ( ! this.isActive ) return true;
      return ( ! this._isDestroyed
        && ( ! this.getContent() || ! this.getContent().contains( e.target ))
        && this.$el && !this.$el.contains(e.target) && e.target !== this.$el );
    },
    selectItems( selected, force ) {

      var mode = this.returnObject ? 'item' : 'value';
      this.lazyValue = selected;

      if ( ! this.multiple ) {

        selected = this.cachedItems.find( i => i[mode] === selected ) || undefined;

        if ( selected !== this.selected ) {
          this.setValue( selected );
        }

      } else {

        if ( selected != null && ! Array.isArray( selected ))
          selected = [ selected ];

        selected = ( selected || [] )
          .map( val => this.cachedItems.find( i => i[mode] === val ))
          .filter( a => a != null );

        const oldValue = this.selected || [];
        const diff = selected.filter( i => oldValue.indexOf(i) !== -1 );

        if ( force || diff.length !== oldValue.length || selected.length !== oldValue.length ) {
          this.selected = selected;
          this.setValue( this.selected );
        }
      }
    },
    unselectItem( item ) {
      if ( ! this.selected ) return;
      if ( this.multiple ) {
        if ( this.mandatory && this.selected.length <= 1 ) return;
        if ( typeof item === 'number' ) item = this.selected[item];
        const index = this.selected.indexOf( item );
        if ( index !== -1 ) {
          const selected = this.selected.slice()
          selected.splice( index, 1 );
          this.onInput( selected );
        }
      } else if ( !this.mandatory ) {
        this.onInput( null );
      }
    },
    computeItems( items ) {
      var values = [];
      return ( items || [] ).map( item => {

        const text = getPropertyFromItem( item, this.itemText, item );
        const value = getPropertyFromItem( item, this.itemValue, text );

        if ( values.includes( value )) return;
        values.push( value );

        const disabled = getPropertyFromItem( item, this.itemDisabled, false );
        return { text, value, disabled, item };
      })
      .filter( Boolean );
    },
    truncateChips() {

      this.getChips();

      const { chips, selection } = this.$refs;
      if ( ! chips || ! selection ) return;

      const scroll = this.chipsIndex === -1;
      const scrollLeft = scroll
        ? Math.max( Math.ceil( chips.scrollWidth - selection.clientWidth ), 0 )
        : chips.scrollLeft;

      if ( chips.scrollWidth > selection.clientWidth ) {
        this.ellpisisRight = scrollLeft + selection.clientWidth < chips.scrollWidth;
        this.ellpisisLeft = scrollLeft > 0;
      } else {
        this.ellpisisLeft = this.ellpisisRight = false;
      }

      scroll && this.$nextTick(() => chips.scrollLeft = chips.scrollWidth );
    },
    onMouseDown() {
      this.$refs.elm && ( this.$refs.elm.hasMouseDown = true );
    },
    onMouseUp() {
      this.$refs.elm && ( this.$refs.elm.hasMouseDown = false );
      this.focus( null, this.multiple );
    },
    /** @public */
    focus( event, active ) {
      this.$refs.elm && ( this.$refs.elm.isFocused = true );
      this.$refs.input && this.$refs.input.focus();
      this.isActive = !!active;
      event && this.$emit( 'focus', event );
    },
    /** @public */
    blur( event ) {
      if ( this.$refs.elm && this.$refs.elm.hasMouseDown ) return;
      this.$refs.elm && ( this.$refs.elm.isFocused = false );
      this.$refs.input && this.$refs.input.blur();
      this.isActive = false;
      event && this.$emit( 'blur', event );
    },
    /** @public **/
    setTile( index ) {
      this.listIndex = index;
    },
    /** @public **/
    nextTile() {
      VMenu.options.methods.nextTile.call( this );
    },
    /** @public **/
    prevTile() {
      VMenu.options.methods.prevTile.call( this );
    },
    /** @public **/
    selectChip( index ) {
      this.chipsIndex = index;
    },
    /** @public **/
    nextChip() {
      const chip = this.chipsElms[ this.chipsIndex + 1 ];
      if ( ! chip ) {
        if ( ! this.chipsElms.length ) return;
        this.chipsIndex = -1;
        this.nextChip();
        return;
      }
      this.chipsIndex++;
    },
    /** @public **/
    prevChip() {
      const chip = this.chipsElms[ this.chipsIndex - 1 ];
      if ( ! chip ) {
        if ( ! this.chipsElms.length ) return;
        this.chipsIndex = this.chipsElms.length;
        this.prevChip();
        return;
      }
      this.chipsIndex--;
    }
  },
  watch: {
    value: 'selectItems',
    listIndex( next, prev ) {
      const content = this.getContent();
      const tile = this.tiles[next];
      if ( tile ) tile.classList.add('--highlighted');
      if ( prev in this.tiles ) this.tiles[prev].classList.remove('--highlighted');
      if ( content ) content.scrollTop = tile ? tile.offsetTop - tile.clientHeight : 0;
    },
    chipsIndex( next, prev ) {
      const { chips } = this.$refs;
      const chip = this.chipsElms[next];
      if ( chip ) chip.classList.add('--highlighted');
      if ( prev in this.chipsElms ) this.chipsElms[prev].classList.remove('--highlighted');
      if ( next > -1 ) {
        this.isActive = false;
        this.listIndex = -1;
      }
      if ( chips ) {
        chips.scrollLeft = chip ? chip.offsetLeft - chip.clientWidth : chips.scrollWidth;
        this.truncateChips( !!chip );
      }
    },
    isActive( value ) {
      !value && this.setTile(-1);
    },
    items: {
      inmediate: true,
      handler( value ) {
        if ( ! this.cacheItems ) {
          this.lastItem = 20;
          this.cachedItems = this.computeItems( value );
          this.setTile(-1);
        } else {
          this.cachedItems = this.computeItems( this.cachedItems.concat( value ));
        }
        this.selectItems( this.lazyValue );
      }
    },
    multiple: {
      inmediate: true,
      handler( value ) {
        if ( value ) {
          this.internalValue && this.onInput([ this.internalValue ]);
        } else if ( Array.isArray( this.internalValue )) {
          this.onInput( this.internalValue[0] || null );
        }
      }
    },
    selected() {
      this.chips && this.$nextTick( this.truncateChips );
    }
  },
  beforeMount() {
    this.cachedItems = this.computeItems( this.items );
  },
  mounted() {
    this.selectItems( this.value, true );
  }
};
</script>

<style>
.selectfield .input__content {
  position: relative;
}
.selectfield__selection {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 0;
  display: flex;
  align-items: center;
}
.selectfield__selection > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.selectfield__input {
  width: 0;
}
.selectfield__input:focus {
  border: 0;
  outline: 0;
}
.selectfield.--single-line .input__label > * {
  cursor: default !important;
}
.selectfield.--is-menu-active.--menu-offsetY:not(.--menu-top):not(.--tile) .input__wrapper {
  border-radius: 4px 4px 0 0;
}
.selectfield.--is-menu-active.--menu-offsetY.--menu-top:not(.--tile) .input__wrapper {
  border-radius: 0 0 4px 4px;
}
.selectfield__menu_content.--offset-y:not(.--top) {
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}
.selectfield__menu_content.--offset-y.--top {
  border-radius: 4px 4px 0 0;
}
.selectfield__menu_content.--outlined {
  border: 1px solid currentColor;
}
.selectfield__item {
  display: flex;
  align-items: center;
  padding: .5em 1em;
  cursor: pointer;
}
.selectfield__item > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.selectfield__item.--highlighted::before {
  opacity: .1;
}
.selectfield__item.v-item--active {
  color: var(--v-primary-base);
}
.selectfield__item.v-item--active::before {
  opacity: .2;
}
.selectfield.theme--light .selectfield__placeholder {
  color: #777;
}
.selectfield.theme--dark .selectfield__placeholder {
  color: #CCC;
}
.selectfield__ellipsis {
  flex: 1 0 auto;
  margin: 0 .25em;
}
.selectfield__chips {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
}
.selectfield__chips > div {
  display: inline-block !important;
  margin: .25em;
  padding: .1em .5em;
}
.selectfield__chips > .--highlighted::before {
  opacity: .2;
}
.selectfield__chips > div > .v-icon {
  font-size: inherit;
}
.selectfield__chips > div:first-child {
  margin-left: 0;
}
</style>
