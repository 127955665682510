<template>
  <v-dialog
    v-model="open"
    :persistent="persistent"
    :max-width="maxWidth"
  >
    <v-card
      color="white"
      class="d-flex flex-column"
      max-height="90vh"
    >
      <v-toolbar
        color="primary"
        elevation="0"
        height="40"
        dark
      >

        <h3 class="subtitle-1">
          {{ title }}
        </h3>

        <v-spacer/>

        <btn v-if="!hideButtonClose" @click="open = false" small icon>
          <v-icon>$close</v-icon>
        </btn>

      </v-toolbar>
      <v-divider/>
      <div
        ref="body"
        class="dialog-body"
        @scroll="scrollTop = $refs.body.scrollTop"
      >

        <slot/>

      </div>
      <v-divider/>
      <v-toolbar color="white" elevation="0" height="60">

        <slot name="prepend-footer" v-bind="{ scrollTop }"/>

        <v-spacer/>

        <btn class="mr-2" @click="onClick('cancel')" text>
          {{ cancelText }}
        </btn>

        <btn color="primary" @click="onClick('save')">
          {{ acceptText }}
        </btn>

      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import Btn from '@/components/Btn';

export default {
  components: { Btn },
  props: {
    value: null,
    title: String,
    persistent: Boolean,
    hideButtonClose: Boolean,
    maxWidth: {
      type: [ String, Number ],
      default: 720
    },
    acceptText: {
      type: String,
      default: 'Guardar'
    },
    cancelText: {
      type: String,
      default: 'Cancelar'
    }
  },
  data() {
    return {
      open: !!this.value,
      scrollTop: 0
    }
  },
  watch: {
    value( value ) {
      this.open = !!value;
    },
    open( value ) {
      this.$refs.body && ( this.scrollTop = this.$refs.body.scrollTop = 0 );
      this.$emit( 'input', value );
    }
  },
  methods: {
    onClick( type ) {
      type != 'save' && ( this.open = false );
      this.$emit(`click:${type}`);
    }
  }
}
</script>

<style>
.dialog-body {
  max-height: calc( 90vh - 100px );
  overflow: auto;
}
</style>
