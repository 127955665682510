<template>
  <div class="mb-8">

    <label v-if="label" class="descriptor-selector-label">
      {{ label }}
    </label>

    <v-card
      class="descriptor-selector-wrapper pa-1"
      min-height="40"
      outlined
    >

      <v-chip
        class="ma-1"
        v-for="cat in selected"
        :key="cat.id"
        :ripple="false"
        color="primary"
        close-icon="mdi-close"
        @click:close="unselect( cat )"
        label outlined close small
      >
        {{ cat.text }}
      </v-chip>

      <v-btn
        :ripple="false"
        @click="dialog = true"
        class="caption ma-1"
        color="grey lighten-1"
        elevation="0"
        height="24"
        dark
      >
        Editar
      </v-btn>

    </v-card>

    <v-dialog
      v-model="dialog"
    >
      <v-card class="descriptor-selector-dialog">

        <v-btn
          class="close-btn"
          color="primary"
          :ripple="false"
          @click="dialog = false"
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <categories
          :value="temp"
          @input="onInput"
        />

        <v-row no-gutter>

          <v-spacer/>

          <v-btn
            class="mr-2"
            color="primary"
            @click="dialog = false"
            text
          >
            Cancelar
          </v-btn>

          <v-btn
            color="primary"
            elevation="0"
            @click="save"
          >
            Acceptar
          </v-btn>

        </v-row>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { deepEqual } from 'vuetify/lib/util/helpers';
import Categories from '@/components/Categories';

export default {
  components: { Categories },
  props: {
    label: String,
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false,
      internalValue: this.value || [],
      temp: []
    }
  },
  computed: {
    ...mapState( 'categories', [ 'categories' ]),
    selected() {
      return this.internalValue.map( id => {
        return this.categories.find( cat => cat.id === id );
      }).filter( Boolean );
    }
  },
  watch: {
    value( value ) {
      this.internalValue = value || [];
    },
    internalValue( value, old ) {
      if ( ! deepEqual( value, old )) {
        this.$emit( 'input', value );
      }
    },
    dialog() {
      this.temp = this.internalValue.slice();
    }
  },
  methods: {
    unselect( cat ) {
      this.internalValue = this.internalValue.slice();
      var i = this.internalValue.indexOf( cat.id );
      if ( i >= 0 ) this.internalValue.splice( i, 1 );
    },
    onInput( selected ) {
      this.temp = selected;
    },
    save() {
      this.internalValue = this.temp;
      this.dialog = false;
    }
  }
}
</script>

<style>
.descriptor-selector-wrapper .v-chip .v-icon {
  font-size: 14px !important;
}
.descriptor-selector-label {
  font-size: 11px;
  color: black;
  padding-left: 8px;
}
.descriptor-selector-dialog {
  position: relative;
  width: 90vw;
  height: 90vh;
}
.descriptor-selector-dialog > .categories {
  bottom: 70px;
}
.descriptor-selector-dialog > .row {
  position: absolute;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 70px;
  bottom: 0;
}
.descriptor-selector-dialog .close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.descriptor-selector-dialog .categories--header .d-btn {
  margin-right: 50px;
}
</style>
