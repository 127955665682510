<template>
  <edit-dialog
    v-model="open"
    :title="title"
    @click:save="submit"
  >
    <v-form
      ref="form"
      style="background:white;"
      class="pa-4"
      @submit.prevent="submit"
    >

      <selectfield
        v-model="model.notready_disposition"
        :items="statuses"
        @input="onInput('notready_disposition',$event)"
        label="Estat de publicació"
        single-line
        outlined
      />

      <select-publications
        label="Publicació"
        v-model="model.id_publications"
        :rules="rules.id_publications"
        single-line
        clearable
        outlined
      />

      <select-states
        label="Estat"
        v-model="model.id_states"
        :rules="rules.id_states"
        single-line
        clearable
        outlined
      />

      <select-types
        label="Tipus"
        v-model="model.id_types"
        :rules="rules.id_types"
        single-line
        clearable
        outlined
      />

      <datefield
        label="Data de la publicació"
        v-model="model.date_publication"
        :rules="rules.date_publication"
        cancelText="Cancelar"
        acceptText="Aplicar"
        single-line
        persistent
        clearable
        outlined
      />

      <textfield
        label="Nº de la publicació"
        v-model="model.num_publication"
        :rules="rules.num_publication"
        single-line
        clearable
        outlined
      />

      <datefield
        label="Data de la disposició"
        v-model="model.date_disposition"
        :rules="rules.date_disposition"
        cancelText="Cancelar"
        acceptText="Aplicar"
        single-line
        persistent
        clearable
        outlined
      />

      <textfield
        label="Nº de la disposició"
        v-model="model.num_disposition"
        :rules="rules.num_disposition"
        single-line
        clearable
        outlined
      />

      <textfield
        label="Titol de la disposició"
        v-model="model.title"
        :rules="rules.title"
        single-line
        clearable
        outlined
      />

      <textareafield
        label="Text de la disposició"
        v-model="model.disposition"
        :rules="rules.disposition"
        min-height="73"
        rows="3"
        single-line
        outlined
      />

      <textfield
        label="Link de la disposició"
        v-model="model.url_disposition"
        :rules="rules.url_disposition"
        single-line
        clearable
        outlined
      />

      <filefield
        label="Fitxer de la disposició (en cas de fitxer serà el link principal)"
        v-model="model.file_disposition"
        :rules="rules.file_disposition"
        :upload="uploadFile"
        error-message="Hi ha hagut un error al pujar l'arxiu."
        single-line
        auto-upload
        clearable
        outlined
      />

      <select-descriptors
        label="Descriptors"
        v-model="model.descriptors"
        :rules="rules.descriptors"
      />

      <select-dispositions
        label="Disposició original que es veu modificada"
        v-model="model.id_parent"
        :rules="rules.id_parent"
        single-line
        clearable
        outlined
      />

      <datefield
        label="Entrada en vigor"
        v-model="model.date_beginning"
        :rules="rules.date_beginning"
        cancelText="Cancelar"
        acceptText="Aplicar"
        single-line
        persistent
        clearable
        outlined
      />

      <datefield
        label="Data d'aplicació"
        v-model="model.date_application"
        :rules="rules.date_application"
        cancelText="Cancelar"
        acceptText="Aplicar"
        single-line
        persistent
        clearable
        outlined
      />

      <textareafield
        v-show="model.date_application"
        v-model="model.info_application"
        :rules="rules.info_application"
        label="Informació sobre la data d'aplicació"
        min-height="73"
        rows="3"
        single-line
        outlined
      />

      <textareafield
        label="Afectacions"
        v-model="model.affects"
        min-height="73"
        rows="3"
        single-line
        outlined
      />

      <textareafield
        label="Notes"
        v-model="model.notes"
        min-height="73"
        rows="3"
        single-line
        outlined
      />

    </v-form>

    <template v-slot:prepend-footer="{ scrollTop }">
      <div v-show="!valid && scrollTop > 0" class="caption error--text">
        <strong>Hi ha errors al formulari</strong></br>
        Revisa les dades insertades.
      </div>
    </template>

  </edit-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import { dispositionTitle, toDate, required } from '@/utils';
import EditDialog from '@/components/Dialog';

// Inputs

import Textfield from '@/components/inputs/Textfield';
import Datefield from '@/components/inputs/Datefield';
import Textareafield from '@/components/inputs/Textareafield';
import Filefield from '@/components/inputs/Filefield';
import Selectfield from '@/components/inputs/Selectfield';

// Selectors

import SelectDispositions from '@/components/selectors/Dispositions';
import SelectPublications from '@/components/selectors/Publications';
import SelectDescriptors from '@/components/selectors/Descriptors';
import SelectStates from '@/components/selectors/States';
import SelectTypes from '@/components/selectors/Types';

// Util

const computeData = data => {
  data = { ...data };
  for ( var key in data ) {
    data[key] = data[key] == null ? null : data[key];
  }
  return data;
};

export default {
  components: {
    EditDialog,
    Textfield,
    Datefield,
    Textareafield,
    Filefield,
    SelectDispositions,
    SelectPublications,
    SelectDescriptors,
    SelectStates,
    SelectTypes,
    Selectfield
  },
  props: {
    value: null,
    data: Object,
    isNew: Boolean,
    title: {
      type: String,
      default: 'Editar disposició'
    }
  },
  data() {
    return {
      open: !!this.value,
      model: {},
      valid: true,
      statuses: [
        { text: 'Despublicada', value: 1 },
        { text: 'Publicada',    value: 0 }
      ],
    }
  },
  computed: {
    rules() {
      return {
        // id_publications: [ required('Selecciona la publicació de la que forma part.') ],
        id_types: [ required('Selecciona el tipus de la disposició.') ],
        // descriptors: [ required('Selecciona el descriptors de la disposició.') ],
        // id_states: [ required('Selecciona l\'estat en el que es troba la disposició.') ],
        // num_publication: [ required('Introdueix el numero de la publicació.') ],
        disposition: [ required('Introdueix el titol de la disposició.' ) ],
        date_disposition: [ required('Introdueix la data de la disposició.' ) ],
        num_disposition: [ required('Introdueix el numero de la disposició.' ) ],
        date_publication: [ required('Introdueix la data de la publicació.' ) ],
        url_disposition: [
          v => !!this.model.file_disposition || required('Introdueix la direccío web de la disposició.' )( v )
        ],
        // id_parent: [],
        // file_disposition: [],
        // date_beginning: [],
        // date_application: [],
        // info_application: [],
      }
    }
  },
  watch: {
    value( value ) {
      this.open = !!value;
    },
    data( value ) {
      this.compute( value );
    },
    open( value ) {
      if ( ! value ) this.compute( this.data );
      this.$emit( 'input', value );
    }
  },
  methods: {
    ...mapMutations([ 'setLoading' ]),
    ...mapMutations( 'app', [ 'setAlert' ]),
    compute( model ) {
      model = model || {};
      this.model = {
        ...model,
        title: !this.isNew && dispositionTitle( model, null, true ) || '',
        descriptors: model.descriptors && model.descriptors.slice() || [],
        date_disposition: model.date_disposition
          ? toDate( model.date_disposition )
          : null,
        date_publication: model.date_publication
          ? toDate( model.date_publication )
          : null,
        date_beginning: model.date_beginning
          ? toDate( model.date_beginning )
          : null,
        date_application: model.date_application
          ? toDate( model.date_application )
          : null
      };
    },
    resetValidation() {
      this.$refs.form && this.$refs.form.resetValidation();
    },
    uploadFile( data ) {
      return this.$store.dispatch( 'uploadFile', data );
    },
    remove( model ) {
      return new Promise(( resolve, reject ) => {
        model = model || this.model;
        this.$store.dispatch( 'api/removeDisposition', model.id ).then(() => {
          this.setAlert({ type: 'success', message: 'Disposició eliminada correctament.' });
          this.$emit('removed');
          resolve();
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
          this.$emit( 'error', err );
          reject( err );
        });
      });
    },
    submit() {
      return new Promise(( resolve, reject ) => {

        if ( ! ( this.valid = this.$refs.form.validate())) return resolve( this.model );

        this.open = false;
        this.setLoading( true );

        this.$store.dispatch( 'api/insertDisposition', computeData( this.model )).then(() => {
          this.setAlert({ type: 'success', message: 'Dades guardades correctament.' });
          this.$emit( 'submit', this.model );
          resolve( this.model );
        }).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
          this.$emit( 'error', err );
          reject( err );
        }).finally(() => {
          this.setLoading( false );
        });
      });
    }
  },
  beforeMount() {
    this.compute( this.data );
  }
}
</script>
