<template>
  <text-field
    v-bind="$attrs"
    v-on="listeners"
    :value="fileName"
    :append-icon="!autoUpload && file || error ? uploadIcon : ''"
    :loading="loading"
    :error="error"
    :error-messages="error ? [errorMessage] : null"
    @click:content="onClick"
    readonly
  >
    <template v-slot:append-outer>
      <input
        v-show="false"
        ref="file"
        type="file"
        :accept="accept"
        @change="onChange"
      />
    </template>
  </text-field>
</template>

<script>
import TextField from './Textfield';

export default {
  inheritAttrs: false,
  components: { TextField },
  props: {
    value: String,
    accept: String,
    upload: Function,
    autoUpload: Boolean,
    errorMessage: {
      type: String,
      default: 'Ha ocurrido un error al subir el archivo.'
    },
    uploadIcon: {
      type: String,
      default: 'mdi-upload'
    }
  },
  data() {
    return {
      file: null,
      fileName: this.value,
      loading: false,
      error: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        'click:append': this.onUpload,
        'click:clear': this.onClear,
        input: () => {},
      }
    },
    computedUploadButton() {
      return this.error || ! this.autoUpload && this.file ? uploadIcon : '';
    }
  },
  watch: {
    value( value ) {
      this.fileName = value;
    }
  },
  methods: {
    onClick(e) {
      this.$emit( 'click', e );
      this.$refs.file && this.$refs.file.click();
    },
    onInput( value ) {
      this.fileName = value;
      this.$listeners.input && this.$listeners.input( value );
    },
    onClear(e) {
      this.file = null;
      this.fileName = '';
      this.error = false;
      this.$listeners['click:clear'] && this.$listeners['click:clear']( e );

      this.$listeners.input && this.$listeners.input( this.fileName );
    },
    onChange(e) {

      const file = this.$refs.file.files[0];
      if ( ! file ) return;

      this.file = file;
      this.fileName = file.name;
      this.$refs.file && ( this.$refs.file.value = null );

      if ( this.autoUpload ) this.onUpload();
      else this.fileName = file.name;
    },
    onUpload() {
      if ( ! this.file ) return;
      if ( this.upload ) {

        const data = new FormData();
        data.append( 'file', this.file );
        this.loading = true;
        this.error = false;

        Promise
          .resolve( this.upload( data, this.file ))
          .then( fileName => this.onInput( fileName || this.file.name ))
          .catch( err => {
            console.error( err );
            this.error = true;
            this.$emit( 'error', err );
          })
          .finally(() => this.loading = false );

      } else {
        console.error( new Error('No se ha especificado una función de subida de archivo.'));
      }
    }
  },
  beforeMount() {
    this.error = false;
  }
};
</script>
